.mod.modMDD {
	&.nav,
	.nav,
	.nav__level1.nav__level1 {
		position: static;
	}
}

scs-header-l1 .nav.modMDD > .nav__level1 {
	border: 0;
	margin-bottom: 1px;
}

div[slot='l1-content-mobile'] {
	background-color: white;

	> sdx-accordion {
		> sdx-accordion-item {
			padding-left: 5px;
			border-left: none;
			border-right: none;
			&:first-child {
				border-top: none;
			}
			> sdx-accordion-item-body > ul.list {
				margin: 0;
			}
		}
	}
}

ul.sc-scs-icon-bar.sc-scs-icon-bar {
	display: flex;
}
