@use 'sass:map';
@use '~@swisscom/sdx/stylesheets/sdx/variables/grid';
@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';

@use 'lib/styles/defs/breakpoints';
@use 'lib/styles/defs/header';

.modL2Navigation {
	position: fixed;
	display: flex;
	justify-content: center;
	height: header.$l2-height;
	width: 100%;
	padding: 20px 0 19px;
	transform: translateY(-200%);
	transition: transform 500ms ease-in-out;
	background: colors.$color-white;
	border-bottom: 1px solid colors.$color-aluminum;
	will-change: transform;
	z-index: -1;
	&.isAuthor {
		position: relative;
		transform: translateY(0);
	}

	&.is-visible {
		transform: translateY(0);
		width: 100%;
		z-index: 4;
	}

	html.has-sdx-header & {
		top: var(--sdx-header-height-mobile);
	}

	html.has-sdx-header.has-crisis & {
		top: calc(var(--sdx-header-height-mobile) + #{header.$crisis-height});
	}

	&--wrapper {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: map.get(grid.$container-widths, ul);
		ul.nav-items {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			li {
				padding-right: 40px;
				a {
					border-bottom: 0;
					color: colors.$color-navy;
					white-space: nowrap;
					overflow: hidden;

					&:hover {
						color: colors.$color-blue;
						text-decoration: none;
					}

					&:active,
					&:focus {
						color: colors.$color-blue;
						text-decoration: none;
					}

					&:visited {
						color: colors.$color-navy;
						&:hover {
							color: colors.$color-blue;
						}
					}
				}
			}
		}
		.nav__level2 {
			ul.nav-items {
				li {
					&:first-of-type {
						padding-left: 20px;
					}
					&:last-of-type {
						padding-right: 0;
					}
					&.active a {
						color: colors.$color-blue;
					}
				}
			}
		}
		a.button {
			margin-left: 40px;
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
			}
			&:visited {
				color: colors.$color-white;
			}
		}
	}
	&__icon {
		cursor: pointer;
	}
	&__title {
		flex: 1 1 auto;
		box-sizing: border-box;
		min-width: 109px;
		font-size: 24px;
		font-weight: 600;
		line-height: 32px;
		color: colors.$color-navy;
		text-align: left;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-right: 10px;
		cursor: auto;
	}

	&__dropdown {
		display: none;
	}

	@media only screen and (max-width: breakpoints.$breakpoint-ul) {
		&--wrapper {
			width: calc(100% - 68px);
		}
	}

	@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
		&--wrapper {
			width: calc(100% - 64px);
		}
	}

	@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
		&--wrapper {
			width: calc(100% - 48px);
			nav.nav__level2 {
				display: none;
			}
			a.button {
				margin-left: 16px;
			}
		}
		&__dropdown {
			width: 100%;
			position: fixed;
			top: 79px;
			left: 0;
			background: colors.$color-white;
			display: none;
			text-align: left;
			min-height: 142px;
			box-shadow: 0 4px 2px -2px colors.$color-aluminum;

			&.is-visible {
				display: flex;
				padding-left: map.get(grid.$grid-gutters, lg);
				nav.nav__level2 {
					display: block;
					ul.nav-items {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						user-select: none;
						li {
							padding-bottom: map.get(grid.$grid-gutters, md);
							&:first-of-type {
								padding-left: 0;
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
		&--wrapper {
			.button {
				font-size: 16px;
				padding: map.get(grid.$grid-gutters, sm) map.get(grid.$grid-gutters, lg);
			}
		}
	}
}
