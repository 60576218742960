@use '~@swisscom/sdx/stylesheets/sdx/variables/sizes';

@use 'lib/styles/defs/breakpoints';

sdx-header.modRenderSdxHeader {
	height: var(--sdx-header-height-mobile);
	&.has-breadcrumb {
		height: var(--sdx-header-height-mobile-with-breadcrumbs);
	}
	@media (min-width: breakpoints.$breakpoint-lg) {
		height: var(--sdx-header-height-desktop);
		&.has-breadcrumb {
			height: var(--sdx-header-height-desktop-with-breadcrumbs);
		}
	}

	width: 100vw; // TODO: Remove when https://jira.swisscom.com/browse/SDX-1383 has been fixed and integrated
}

html.has-sdx-header {
	--sticky-header-height: var(--sdx-header-height-mobile, #{sizes.$navigation-level1-height});
}
