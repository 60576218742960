@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use '~@swisscom/sdx/stylesheets/sdx/variables/global';

@use 'lib/styles/defs/breakpoints';
@use 'lib/styles/defs/header';

/* SmoothScroll.ts padding */
:root {
	--sticky-crisis-height: 0px;
	--sticky-header-height: 0px;
	--sticky-l2-height: 0px;
	--sticky-height: 0px;
	--sticky-height-potential: calc(var(--sticky-crisis-height) + var(--sticky-header-height) + var(--sticky-l2-height));
}

@media only screen and (min-width: breakpoints.$tabletAndDesktop) {
	// Crisis and shared header are sticky starting at 768px
	:root {
		--sticky-header-height: #{header.$sticky-header-height};
	}
}

html {
	scroll-padding-top: var(--sticky-height-potential);

	&.has-l2-nav {
		--sticky-l2-height: #{header.$l2-height};
	}

	&.has-sticky-header {
		--sticky-height: var(--sticky-height-potential);
	}
}

.footer--full {
	.nav-title .nav-link {
		font-size: inherit;
	}

	.footer--social-icons > ul.nav-items > li.nav-item {
		margin-right: 18px;
	}
}

.modSharedHeader {
	sdx-accordion {
		margin: -1px 0;
	}
	.mobileNavLink {
		padding: 13px 0 14px 24px;
		display: block;
		border: 1px solid colors.$color-gray-tint-8;
		&:hover {
			border: 1px solid colors.$color-gray-tint-8;
		}
	}
	.nav.sc-scs-header-l0 {
		// Workaround for https://jira.swisscom.com/browse/DAC-23082
		// Only visible when not using shadow DOM
		z-index: global.$zindex-navigation + 1;
	}
	.tertiary-links-slot > :not(:last-child) {
		margin-right: 1rem;
	}
	.tertiary-links-slot sdx-menu-flyout {
		font-size: 16px;
	}
	scs-header-link-list[slot='tertiary-links-mobile'] {
		sdx-accordion {
			sdx-accordion-item {
				padding-left: 5px;
				border-left: none;
				border-right: none;
				&:first-child {
					border-top: none;
				}
				sdx-accordion-item-body > ul.list {
					margin: 0;
				}
			}
			.accordion-header {
				font-size: 16px;
			}
		}
	}
}
