@use 'lib/styles/defs/breakpoints';

.modHeaderBanner {
	display: none;
	min-height: 40px;

	line-height: 18px;
	letter-spacing: 0.1px;
	font-size: 14px;
	font-weight: 400;

	.container-page {
		margin-left: 0;
	}

	.banner-close {
		position: absolute;
		right: 24px;
		top: 8px;
		width: 24px;
	}

	.banners {
		display: flex;
		flex-flow: column nowrap;
		flex-basis: 100%;
		margin: 0 24px;
		justify-content: flex-start;
		align-content: flex-start;
		column-gap: 16px;
	}

	.banner {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		flex-basis: 50%;
	}

	a.link[class*='icon-']::before {
		padding-right: 10px;
	}

	.row {
		flex-wrap: nowrap;
	}
	@media only screen and (min-width: breakpoints.$breakpoint-lg) {
		line-height: 21px;
		letter-spacing: 0;
		font-size: 16px;

		.banner-close {
			position: relative;
			right: 8px;
			top: 0;
		}

		.banners {
			flex-flow: row nowrap;
			justify-content: center;
			column-gap: 80px;
		}

		.banner {
			justify-content: center;
		}
		.container-page {
			margin-left: auto;
		}
	}
}
