@use 'sass:map';

@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use '~@swisscom/sdx/stylesheets/sdx/variables/grid';

.mod.modBreadcrumb.modBreadcrumb {
	white-space: nowrap;
	border-bottom: 1px solid colors.$color-aluminum;
	z-index: auto;
	overflow-y: hidden;
	font-weight: 400;
	height: 40px;
	> ol {
		font-size: 14px;
		line-height: 40px;
		display: flex;
		@media (min-width: map.get(grid.$grid-breakpoints, md)) and (max-width: map.get(grid.$grid-breakpoints, ul) - 1px) {
			width: calc(100% - (2 * 24px));
		}
		> li {
			display: flex;
			margin-right: 16px;
			&.current {
				margin-right: 0;
				color: colors.$color-navy;
			}
			a {
				display: flex;
				text-decoration: none;
			}
			.icon {
				display: inline-block;
				width: 24px;
				line-height: inherit;
				text-align: center;
			}
			.name {
				display: inline-block;
			}
			&.parent {
				display: none;
			}
			&.parent {
				.icon {
					margin-left: -8px;
				}
			}
		}
	}
	&.minified {
		> ol {
			> li {
				margin-right: 0;
				display: none;
				&.current {
					display: flex;
					overflow: hidden;
					.icon {
						display: none;
					}
					.name {
						display: inline;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				&.parent {
					display: block;
				}
			}
		}
	}
}

@media print {
	.mod.modBreadcrumb.modBreadcrumb {
		display: none;
	}
}
